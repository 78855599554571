.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .button-container {
    display: flex;
    gap: 20px;
  }
  
  .home-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover {
    background-color: #0056b3;
  }
  