  .hotel-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
  }

  .hotel-card {
    display: flex;
    align-items: flex-start;
    border: 5px solid #ddd;
    padding: 10px;
    box-sizing: border-box;
    text-align: left;
    height: auto;
    width: 1300px;
    justify-content: space-between;
    align-items: flex-start;
  }

  .main-content {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }
  
  .hotel-desc {
    flex: 1;
    margin: 0;
  }
  
  .hotel-price-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 200px;
    margin-right: 5rem;
    margin-top: 5rem;
  }
  
  .hotel-card img {
    height: auto;
    margin-right: 20px;
  }
  
  .hotel-card .hotel-info {
    flex: 1;
  }
  
  .hotel-card h3 {
    font-size: 18px;
  }
  
  .hotel-card p {
    font-size: 14px;
    color: #555;
  }
  
  .hotel-card .hotel-actions {
    text-align: right;
  }
  
  .hotel-card .hotel-price {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .hotel-image-box {
    width: 100%;
    max-width: 300px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .hotel-image {
    width: 80%;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
  }

  .hotel-card .discount {
    text-decoration: line-through;
    color: red;
    margin-left: 10px;
    font-size: large;
  }

  .hotel-card .price {
    font-size: x-large
  }

  .hotel-card .free-shipping-text {
    color: green;
  }
  
  .hotel-card button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 13px 30px;
    cursor: pointer;
  }
  
  .hotel-card button:hover {
    background-color: #0056b3;
  }

  .star-rating {
    display: flex;
    gap: 4px;
    margin: 8px 0;
  }
  
  .star {
    font-size: 20px;
    color: #ccc;
  }
  
  .star.filled {
    color: #f5c518;
  }
