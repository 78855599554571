.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 20%;
  min-width: 200px;
  padding: 10px;
  background-color: #f8f9fa;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
  height: 90vh;
}

.sidebar section {
  margin-bottom: 20px;
  width: 100%;
}

.sidebar h3 {
  margin-top: 0;
  padding: 10px;
  background-color: #f1f1f1;
  cursor: pointer;
  display: flex;
  justify-content: space-between; /* Align text and icon at opposite ends */
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.toggle-icon {
  font-size: 18px;
  transition: transform 0.3s; /* Smooth rotation */
}

.toggle-icon.open {
  transform: rotate(180deg); /* Rotate the "V" when the section is open */
}

.sidebar .price-range {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Add some space between the inputs */
}

.sidebar .price-range div {
  display: flex;
  flex-direction: column;
  width: 48%; /* Ensure the inputs share space equally */
}

.sidebar input[type="number"] {
  width: 100%;
  height: 45px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 0.2rem;
}

.sidebar .price-button {
  margin-top: 30px;
  padding: 10px 150px;
  background-color: transparent;
  color: black;
  font-weight: 700;
  border: 1px solid #555;
  cursor: pointer;
}

.sidebar .size-button {
  background-color: #c9c6ac;
  color: black;
  margin-right: 2rem;
  margin-top: 1rem;
  padding: 15px 25px;
}

.sidebar button:hover {
  background-color: #555;
}

.ratings div {
  display: block;
  margin-bottom: 5px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  list-style: none;
  padding: 2px;
  font-weight: 700;
  margin: 0;
}